@media (max-width: 500px){
    .video-final video
    {
        max-width: 90%;
    }

    .mt-15 {
        margin-top: 4rem !important;
    }
    .mb-15 {
        margin-bottom: 6rem !important;
    }
    .mb-10 {
        margin-bottom: 4rem;
    }
    .row
    {
        margin-left: 0!important;
    }

    .nav-bar
    {
        align-items: initial !important;
        margin-left: 15px;
    }

    .nav-dropdown-box
    {
        display: block !important;
    }

    .dropdown-menu
    {
        box-shadow: none;
    }

    .nav-dropdown-box
    {
        padding: 0px 20px;
    }

    .offcanvas-header
    {
        justify-content: end !important;
        margin-top: 15px;
        margin-right: 15px;
    }

    .home-details h1
    {
        font-size: 40px;
        font-weight: 600;
    }
    .footer-bottom
    {
        flex-direction: column;
        align-items: center;
    }

    .talk-to-expert button, .product-get-started button, .product-signin button, .signup-box .signup-btn,
    .case-btn .read-btn button, .see-more-btn button, .reday-btn button, .learn-more-btn button, .search-btn
    {
        padding: 8px 16px !important;
    }

    .talk-to-expert button
    {
        width: 140px;
    }

    .watch-video
    {
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
    }

    .supply-box,
    .signup-box,.supply_chain--whitebx
    {
        padding: 2rem!important;
    }

    .keySucess-container,
    .product-container
    {
        padding-top: 2rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .signup,
    .nes-community
    {
        padding: 3rem 1.5rem 3rem !important;
    }

    .keySucess-head h3, .supply-chain__head h3, .find-heading h3, .case-study-head h3, .case-header h3, .signup-heading h3, .collaboration-head h3, .nes-community__header h3,
    .leading-brands h3, .product-heading1 h3, .collaboration-header h3, .ready-heading h3, .product-faq-heading h3, .further-head h3, .nes-head h3, .blog-title,
    .offcanvas-header .offcanvas-title, .terms-subheading h3
    {
        font-size: 22px;
    }

    .keySucess-paragraph p, .find-paragraph p, .case-paragraph p, .signup-paragraph p .signup-agree, .nes-community__paragrapgh p, .supply-paragrapgh p
    ,.product-paragraph p, .product-tag-paragraph p, .collaboration-paragraph p, .ready-paragraph p, .further-paragraph p, .nes-paragraph p, .faq-super-heading, .faq-sub-heading,
    .glossary-detail-paragraph, .related-links--item, .offcanvas-header .offcanvas-sub-heading
    {
        font-size: 14px;
    }
    .product-heading h1, .terms-heading h1
    {
        font-size: 30px;
    }
    .product-topic h4,
    .nes-tailor h3, .termsdate h3
    {
        font-size: 16px;
    }
    .product-box
    {
        margin-bottom: 20px;
    }
    .ready,
    .nes-tailor
    {
        align-items: center;
        padding: 50px 10px !important;
    }
    .enquiry-box
    {
        margin-top: 0px;
        margin-left: 0px;
        padding: 20px 12px;
        height: auto;
    }
    .enquiry-box input,
    .enquiry-box textarea
    {
        width: 300px;
    }
    .nes
    {
        margin-top: 600px;
    }
    .nes-box
    {
        margin-bottom: 20px;
    }
    .nes-paragraph p
    {
        padding: 0 !important;
    }
    .nes-img
    {
        padding: 1rem 0rem!important;
    }
    .nes-box1,.nes-box2,.nes-box3,.nes-box4,.nes-box5, .nes-box6,.nes-box7,.nes-box8
    {
        height: auto;
    }

    .nes-box7
    {
        margin-top: 0;
    }

    .leading-brands-logos__list
    {
        flex-direction: column;
    }
    .contactBg
    {
        height: auto !important;
    }
    .contactus,
    .contactus .contactus-content
    {
        height: auto;
    }
    .contactus
    {
        padding: 70px 0;
    }
    .offcanvas .nav-link
    {
        font-size: 20px !important;
        margin-top: 20px !important;
    }

    .social-icon i
    {
        margin-left: 10px;
    }
    .faq-form input
    {
        padding: 14px 10px;
    }

    .offcanvas-end
    {
        width: 100%;
    }
    .offcanvas-body {
        padding: 10px 25px;
    }
    .offcanvas-header
    {
        padding: 25px 50px;
    }
    .offcanvas .search-nav li:first-child {
        display: block !important;
    }
    .terms-paragrapgh p
    {
        margin-top: 2rem;
    }

    .footer-middle .row .col-sm-6
    {
        width: 50% !important;
    }
    .search-nav ul
    {
        position: relative !important;
        display: block;
    }
    .search-nav ul li
    {
        margin-bottom: 20px;
    }
    .search-nav
    {
        display: block !important;
    }
    .copyright
    {
        padding-left: 30px 0;
    }
    .copyright .rights h5, .copyright .terms h5, .main-calculator .calculator-form .form-control, .main-calculator .calculator-form .form-label, .main-calculator .calculator-form .transportation-select-label
    {
        font-size: 12px;
    }
    .contact-image img
    {
        width: 300px;
        height: 200px;
    }
    .footprint__calculator
    {
        padding: 55px 10px 0px;
    }
}